import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 2em;
  margin-left: 2em;

  h1 {
    margin-bottom: 1rem;
    font-family: 'Fjalla One', sans-serif;
    font-size: 2em;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    padding: 25px 30px 20px 30px;
    color: #fff;
    background-color: #00ADEF;

  }
  @media only screen and (min-width: 1000px) {
    h1 {
      font-size: 3em;
    }
  }
`;
