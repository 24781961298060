import styled from 'styled-components';

export const Container = styled.div`
    margin-right: 2em;
    margin-left: 2em;
  p {
    margin-bottom: 2rem;
    font-size: 1.6em;
    line-height: 3rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
  }
  strong {
    font-weight: bold;
  }
  h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 2.5em;
    padding-bottom: 1em;
  }
  h3 {
    font-weight: bold;
    font-family: 'Fjalla One', sans-serif;
    font-size: 2.4em;
    padding-bottom: 0.4em;
    padding-top: 0.6em;
  }

  @media screen and (max-width: 720px) {
    p {
      margin-bottom: 2rem;
      font-family: 'Fjalla One', sans-serif;
      font-size: 1.6em;
      line-height: 3rem;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0em;
    }
  }
`;
