import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Titre from 'components/titre';
import Texte from 'components/texte';
import { ReactTypeformEmbed } from 'react-typeform-embed';

export default function Template({ data }) {
  const { markdownRemark, allMenuYaml } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout
      pageTitle={frontmatter.title}
      lang={frontmatter.lang}
      menuLinks={allMenuYaml.nodes[0].menuItems}
    >
      <div className="pageGen">
        <Titre pageTitle={frontmatter.title} />
        <Texte pageTexte={html} />
        <div className="typeform">
          <ReactTypeformEmbed popup={false} url={frontmatter.typeform} />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!, $lang: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        typeform
        lang
      }
    }
    allMenuYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        menuItems {
          primaire {
            lien
            titre
          }
          produits {
            lien
            titre
          }
          services {
            lien
            titre
          }
          apropos {
            lien
            titre
          }
          accueil
          servicesTitre
          produitsTitre
          alert
        }
      }
    }
  }
`;
