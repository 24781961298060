import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './texte.css';

const Texte = ({ pageTexte }) => (
  <Container>
    <div dangerouslySetInnerHTML={{ __html: pageTexte }} />
  </Container>
);

Texte.propTypes = {
  pageTexte: PropTypes.string.isRequired,
};

export default Texte;
