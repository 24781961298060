import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './titre.css';

const Titre = ({ pageTitle }) => (
  <Container>
    <h1>{pageTitle}</h1>
  </Container>
);

Titre.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default Titre;
